import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";

import homepage1 from './projektet/homepage1.png';
import aboutus from './projektet/aboutus.png';
import contactform from './projektet/contactform.png';
import login from './projektet/loginforma.png';
import aplication from './projektet/aplikimi1.png';
import dashboard from './projektet/dashboard.png'
import qytetet1 from './projektet/qytetet1.png'
import accountsummary from './projektet/accountsummary.png'
import addclient from './projektet/addclient.png'
import manageclient from './projektet/manageclient.png'
import aplicantsonline from './projektet/aplicantsonline.png'
import gmaill from './projektet/gmaill.png'
import transfert from './projektet/transfert.png'
import savetrasnfer from './projektet/savetrasnfer.png'
import interhistory from './projektet/interhistory.png'
import reports from './projektet/reports.png'
import profile from './projektet/profile.png'
import logs from './projektet/logs.png'
import loans from './projektet/loans.png'
import payment from './projektet/payment.png'




const CarouselComponent = () => {
  return (
    <div className="container-fluid"  >
    <div className="container">
          <div className="position-relative d-flex align-items-center justify-content-center b-4" >
    <h1 className="position-absolute text-uppercase text-primary">Ebanking</h1><div className="carousel-container">

      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        autoPlay={true}
        className="my-carousel"
      >
        <div>
          <img src={homepage1} alt="Homepage" />
          <p className="legend">Homepage</p>
        </div>
        <div>
          <img src={aboutus} alt="AboutUs" />
          <p className="legend">AboutUs</p>
        </div>
        <div>
          <img src={contactform} alt="ContactForm" />
          <p className="legend">Slide 3</p>
        </div>
        <div>
          <img src={login} alt="LoginForm" />
          <p className="legend">LoginForm</p>
        </div>
        <div>
          <img src={aplication} alt="Aplication Online" />
          <p className="legend">Aplication Online</p>
        </div>
        {/* <div>
  <img src={qytetet1} alt="Slide 4" />
  <p className="legend">Slide 4</p>
</div> */}
        <div>
          <img src={dashboard} alt="Dashboard" />
          <p className="legend">Dashboard</p>
        </div>
        <div>
          <img src={accountsummary} alt="AccountSummary" />
          <p className="legend">Account Summary</p>
        </div>
        <div>
          <img src={addclient} alt="addclient" />
          <p className="legend">Add Client</p>
        </div>
        <div>
          <img src={manageclient} alt="Slide 4" />
          <p className="legend">Manage Client</p>
        </div>
        <div>
          <img src={aplicantsonline} alt="Slide 4" />
          <p className="legend">Manage ApplicantsOnline</p>
        </div>
        <div>
          <img src={gmaill} alt="Slide 4" />
          <p className="legend">Accpted Applicants</p>
        </div>
        <div>
          <img src={transfert} alt="Slide 4" />
          <p className="legend">Transfer Money</p>
        </div>
        <div>
          <img src={savetrasnfer} alt="Slide 4" />
          <p className="legend">Transfer To SaveTransfer</p>
        </div>
        <div>
          <img src={interhistory} alt="Slide 4" />
          <p className="legend">Transfer History</p>
        </div>
        <div>
          <img src={reports} alt="Slide 4" />
          <p className="legend">All Reports</p>
        </div>
        <div>
          <img src={profile} alt="Slide 4" />
          <p className="legend">Your Profile</p>
        </div>

        <div>
          <img src={logs} alt="Slide 4" />
          <p className="legend">Admins Logs</p>
        </div>

        <div>
          <img src={loans} alt="Slide 4" />
          <p className="legend">Loans</p>
        </div>

        <div>
          <img src={payment} alt="Slide 4" />
          <p className="legend">Payments</p>
        </div>




      </Carousel>
    </div>
    </div>
    </div>
     </div>
  );
};

export default CarouselComponent;
