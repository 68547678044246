import React, { useRef, useState } from 'react';

import Home from './components/Home'
import Nav from './components/nav/Nav'
import Contact from './components/Contact'
import CarouselComponent from './components/CarouselComponent'
const App = () => {
  return (

    <>
      <Home />
      <CarouselComponent/>
      <Nav />
      <Contact />

      


    </>
  )
}

export default App